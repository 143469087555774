<template>
  <v-text-field
    v-model="quantity"
    type="number"
    dense
    outlined
    label="Cantitate"
    append-outer-icon="mdi-plus"
    @click:append-outer="quantity++"
    prepend-icon="mdi-minus"
    @click:prepend="quantity--"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    quantity: {
      get() {
        return this.value
      },
      set(val) {
        const newVal = val < 1 ? 1 : val
        this.$emit('input', newVal)
      },
    },
  },
}
</script>
