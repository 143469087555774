<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-tabs center-active fixed-tabs show-arrows v-model="tab">
          <v-tab v-for="day in days" :key="day">
            {{ day }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="day in days" :key="day">
            <div v-if="!products.length">
              <v-skeleton-loader v-for="i in 3" :key="i" class="mt-3" type="image"></v-skeleton-loader>
            </div>
            <v-card
              v-for="productDay in productsForDay(day)"
              :key="productDay.id"
              class="mx-auto mt-3"
              outlined
            >
              <v-card-text>
                <v-row>
                  <v-col :md="2" :cols="12">
                    <quantity-input v-model="productDay.quantity" />
                  </v-col>
                  <v-col :md="9" :cols="12">
                    <v-autocomplete
                      v-model="productDay.product"
                      :items="products"
                      dense
                      outlined
                      label="Produs"
                    ></v-autocomplete>
                  </v-col>
                  <v-col :md="1" :cols="12">
                    <v-btn @click="deleteProductDay(productDay.id)" fab dark small color="error">
                      <v-icon dark>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-btn @click="addProductDay(day)" class="mt-3" rounded color="success">
              Adaugă produs
            </v-btn>
          </v-tab-item>
        </v-tabs-items>
        <v-btn @click="showSendDialog" class="mt-10" rounded color="primary">
          Trimite comandă
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Nume comandă</v-card-title>

        <v-card-text>
          <v-text-field v-model="name" label="Nume comandă" outlined></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="sendOrder">
            Trimite
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios'
import QuantityInput from './QuantityInput.vue'
export default {
  name: 'HelloWorld',
  components: {
    QuantityInput,
  },

  data: () => ({
    days: ['Luni', 'Marți', 'Miercuri', 'Joi', 'Vineri'],
    tab: null,
    idCounter: 0,
    snackbar: {
      text: '',
      show: false,
    },
    dialog: false,
    name: '',
    products: [],
    productsDays: [],
  }),

  async mounted() {
    await this.getProducts()
    this.addDefaults()
  },

  methods: {
    getProducts() {
      return axios
        .get('/api/products')
        .then(
          ({ data: { products } }) =>
            (this.products = products.map((prod) => ({ text: prod.name, value: prod._id })))
        )
    },
    productsForDay(day) {
      return this.productsDays.filter((prodDay) => prodDay.day === day)
    },

    deleteProductDay(idLocal) {
      this.productsDays = this.productsDays.filter(({ id }) => idLocal !== id)
    },

    addProductDay(day) {
      this.productsDays.push({ day, product: this.products[0].value, quantity: 1, id: ++this.idCounter })
    },

    addDefaults() {
      this.days.forEach(this.addProductDay)
    },

    showSendDialog() {
      this.dialog = true
      this.name = ''
    },

    sendOrder() {
      if (this.name === '') {
        this.snackbar = {
          show: true,
          text: 'Numele nu poate fi gol',
        }
      } else {
        axios
          .post('/api/send-order', { productsDays: this.productsDays, name: this.name })
          .then(({ data: { status } }) => {
            if (status === 'Added') {
              this.productsDays = []
              this.dialog = false
              this.snackbar = { text: 'Comanda a fost trimisă!', show: true }
            }
          })
      }
    },
  },
}
</script>
